<template>
  <div class="secharts animate__animated animate__fadeIn">
    <div class="header">
      <Headers :header_data="[{title:'学情分析'}]" />
    </div>
    <!-- 下拉框，日期选择器 -->
    <main>
      <div class="select">
        <el-select v-model="currentJxid" class="selsectbox" style="width:40%;height:2vw;float:left;" placeholder="请选择" @change="selectChange">
          <el-option v-for="item in options" :key="item.value" :label="item.jxmc" :value="item.jxid" />
        </el-select>
        <div class="icon">
          <img src="../../../assets/images/student/left.png" alt="">
        </div>
        <el-date-picker :disabled-date="dealDisabledDate" value-format="YYYY-MM-DD" :clearable="false" style="float:right;width:50%;height:2vw;" v-model="time_select" type="daterange" range-separator="——" start-placeholder="开始日期" end-placeholder="结束日期" @change="timeChange()"></el-date-picker>
      </div>
      <div class="main">
        <!-- 1 我的成绩和进步曲线 -->
        <div class="main0">
          <!-- 我的成绩 -->
          <div class="box box0">
            <div class="main-title"><span> 我的成绩 </span> </div>
            <div class="images">
              <div class="img1 img">
                <div class="img-title">
                  <span>累计学习课文时长</span>
                  <span style="font-size:1vw;">(学习+测试)</span>
                </div>
                <div class="img-main">
                  <span>{{ ljxxsc ? ljxxsc : '0时' }}</span>
                </div>
              </div>
              <div class="img2 img">
                <div class="img-title">
                  <span>学习课文总数</span>
                </div>
                <div class="img-main">
                  <span>{{ (xxkwzs ? xxkwzs : 0) + '课' }}</span>
                </div>
              </div>
              <div class="img3 img">
                <div class="img-title" style="height:35%">
                  <span>整体情况</span>
                </div>
                <div class="img3-main" style="height:65%">
                  <div class="gauge" id="gauge"></div>
                </div>
              </div>
            </div>
          </div>
          <!-- 进步曲线 -->
          <div class="right1 box">
            <div class="main-title"><span> 进步曲线（每日背课文量） </span></div>
            <!-- 折线图 -->
            <div id="broke" class="broke"></div>
          </div>
        </div>
        <!--2  学习统计柱状图 -->
        <div class="main1">
          <div class="left2  box2">
            <div class="main-title"><span> 学习统计（课文星级获得情况） </span></div>
            <!-- 柱状图 -->
            <div class="category" id="category"></div>
          </div>
        </div>
      </div>
    </main>
    <Footer />
  </div>
</template>
<script>
import { onMounted, reactive, toRefs, onBeforeUnmount, getCurrentInstance } from "vue";
import { useStore } from "vuex";
import { ElMessage } from "element-plus";
import Headers from '@/views/student/components/zjfheader.vue'
import Footer from '@/views/student/components/footer.vue'
import { studyReportApi, getTrains, studyAnalysisApi } from '@/api/student'
import * as echarts from 'echarts';
import { BarChart } from 'echarts/charts';
// 引入提示框，标题，直角坐标系组件，组件后缀都为 Component
import { TitleComponent, TooltipComponent, GridComponent } from 'echarts/components';
// 引入 Canvas 渲染器，注意引入 CanvasRenderer 或者 SVGRenderer 是必须的一步
import { CanvasRenderer } from 'echarts/renderers';
// 注册必须的组件
echarts.use([TitleComponent, TooltipComponent, GridComponent, BarChart, CanvasRenderer])
export default {
  components: {
    Headers, Footer
  },
  setup() {
    const { proxy } = getCurrentInstance();
    const store = useStore();
    // console.log(document.getElementById('broke'))
    var brokeEcharts = reactive(null);
    var categoryEcharts = reactive(null);
    var gaugeEcharts = reactive(null);
    onBeforeUnmount(() => {
      // brokeEcharts.dispose(); //销毁
      // categoryEcharts.dispose(); //销毁
      // gaugeEcharts.dispose(); //销毁
    })
    const data = reactive({
      jxList: [],
      box_data: {
        dialogVoice: false,
        sysVoices: 'y'
      },
      time_section: [],//
      currentJxkssj: '',
      currentJxjssj: '1',
      currentJxid: '1', //集训id
      time_select: [], // x选择得时间
      currentData: {},
      options: [
        { jxmc: '2021夏令营', jxid: '1' },
        { jxmc: '2022夏令营', jxid: '2' },
      ],
      ljxxsc: null,//学习课文总数
      xxkwzs: null,//累计学习时长
      // 折线图配置
      brokeOption: {
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: []
        },
        yAxis: {
          type: 'value'
        },
        tooltip: {
          trigger: 'axis'
        },
        series: [{
          data: [],
          type: 'line',
          // showSymbol: false,
          areaStyle: {
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [{
                offset: 0, color: '#b8d0ff' // 0% 处的颜色
              }, {
                offset: 1, color: '#f0f5ff' // 100% 处的颜色
              }],
              globalCoord: false // 缺省为 false
            }
          }
        }],
        grid: {
          show: true,
          top: '5%',
          left: '7%',
          right: '7%',
          bottom: '40%'
        },
        dataZoom: [{
          type: 'slider',
          show: true,
          // left: '9%',
          realtime: true,
          bottom: '20vw',
          height: 15,
          start: 0,
          end: 50 //初始化滚动条
        }],
      },
      // 柱状图配置
      categoryOption: {
        color: [
          new echarts.graphic.LinearGradient(0, 1, 0, 0, [{
            offset: 0, color: '#0974e4'
          },
          {
            offset: 1, color: '#95c7ff'
          }
          ]),
          new echarts.graphic.LinearGradient(0, 1, 0, 0, [{
            offset: 0, color: '#57c874'
          },
          {
            offset: 1, color: '#adffc5'
          }
          ]),
          new echarts.graphic.LinearGradient(0, 1, 0, 0, [{
            offset: 0, color: '#f8d542'
          },
          {
            offset: 1, color: '#ffecab'
          }]),
          new echarts.graphic.LinearGradient(0, 1, 0, 0, [{
            offset: 0, color: '#f23f32'
          },
          {
            offset: 1, color: '#ffb5ad'
          },]),
        ],
        legend: {
          height: '10%',
          icon: "circle",
          right: '10%',
          formatter: function (name) {
            const show_text = name == 'sxs' ? '三星' : name == 'exs' ? '二星' : name == 'yxs' ? '一星' : name == 'lxs' ? '零星' : ''
            return show_text
          },
          data: ['product', 'sxs', 'exs', 'yxs', 'lxs'],
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function (val) {
            let v = val[0].data
            const text =
              "<span style='font-weight:600;color:#666;line-height:30px'>" + v.product + "</span>" + "<br/>"
              + "<span style='color:#0974e4'>三&nbsp;&nbsp;星</span> " + "&nbsp;&nbsp;&nbsp;&nbsp;" + v.sxs + "<br/>"
              + "<div'><span style='color:#57c874'>二&nbsp;&nbsp;星</span>" + "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;" + v.exs + "</div><br/>"
              + "<div'><span style='color:#f8d542'>一&nbsp;&nbsp星</span>" + "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;" + v.yxs + "</div><br/>"
              + "<span style='color:#f23f32'>零&nbsp;&nbsp;星</span></span>" + "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;" + v.lxs
            return text;
          },
        },
        dataset: {
          // dimensions: ['product', 'sxs', 'exs', 'yxs', 'lxs'],
          source: []
        },

        xAxis: {
          type: 'category',
        },
        yAxis: {
          type: 'value',
          min: 0,
          // max: 100,
          // axisLabel: {
          //   show: true,
          //   interval: 'auto',
          //   formatter: '{value}%'
          // },
        },
        series: [
          {
            type: 'bar',
            barWidth: '25vw',
            label: { //柱体上显示数值
              show: true,//开启显示
              position: 'top',//在上方显示
              textStyle: {//数值样式
                fontSize: '0.6rem',
                color: '#333'
              },
              formatter: function (val) {
                return val.data.total ? (Math.round(val.data['熟词'] / 100 * val.data.total) + '个') : ''
              },
            }
          },
          {
            type: 'bar',
            barWidth: '25vw',
            label: { //柱体上显示数值
              show: true,//开启显示
              position: 'top',//在上方显示
              textStyle: {//数值样式
                fontSize: '0.6rem',
                color: '#333'
              },
              formatter: function (val) {
                return val.data.total ? (Math.round(val.data['夹生词'] / 100 * val.data.total) + '个') : ''
              },
            }
          },
          {
            type: 'bar',
            barWidth: '25vw',
            label: { //柱体上显示数值
              show: true,//开启显示
              position: 'top',//在上方显示

              textStyle: {//数值样式
                fontSize: '0.6rem',
                color: '#333'
              },
              formatter: function (val) {
                return val.data.total ? (Math.round(val.data['生词'] / 100 * val.data.total) + '个') : ''
              }
            }
          },
          {
            type: 'bar',
            barWidth: '25vw',
            label: { //柱体上显示数值
              show: true,//开启显示
              position: 'top',//在上方显示
              textStyle: {//数值样式
                fontSize: '0.6rem',
                color: '#333'
              },
              formatter: function (val) {
                return val.data.total ? (Math.round(val.data['生词'] / 100 * val.data.total) + '个') : ''
              }
            }
          },
        ],
        grid: {
          // show: false,
          // top: '10%',
          left: '5%',
          right: '5%',
          bottom: '25%'
        },
        dataZoom: [{
          type: 'slider',
          show: true,
          // left: '9%',
          height: 15,
          realtime: true,
          bottom: '8%',
          start: 0,
          end: 100 //初始化滚动条
        }],
      },
      // 仪表盘配置
      gaugeOption: {
        grid: {
          show: false,
          top: '10%',
          left: '5%',
          right: '5%',
          bottom: 0,
        },
        series: [
          {
            type: 'gauge',
            startAngle: 180,
            endAngle: 0,
            min: 0,
            max: 1,
            splitNumber: 8,
            radius: '100%', // 大小
            axisLine: {
              lineStyle: {
                width: 6,
                color: [
                  [0.26999, '#FDDD60'],
                  [0.72999, '#7CFFB2'],
                  [1, '#3FA0FF']
                ]
              }
            },
            pointer: {
              icon: 'path://M12.8,0.7l12,40.1H0.7L12.8,0.7z',
              length: '20%',
              width: 10,
              offsetCenter: [0, '-60%'],
              itemStyle: {
                color: 'auto'
              }
            },
            axisTick: {
              length: 0,
            },
            splitLine: {
              length: 0,
            },
            axisLabel: {
              color: '#464646',
              fontSize: 20,
              distance: -60,
              formatter: function (value) {
              }
            },
            detail: {
              fontSize: 16,
              offsetCenter: [0, '20%'],
              valueAnimation: true,
              formatter: function (value) {
                let str = null
                if (value >= 0.73) {
                  str = '优'
                } else if (value < 0.73 && value >= 0.27) {
                  str = '良'
                } else {
                  str = '差'
                }
                return str
              },
              color: 'auto'
            },
            data: [{
              value: 1,
            }]
          }]
      }
    });
    // 页面加载完毕执行
    onMounted(() => {
      getStudyReport()
      // getTrains({ yhid: store.state.userInfo.yhid, yycdid: store.state.yycdid }).then(res => {
      //   if (res.success) {
      //     if (res.result.length) {
      //       console.log(res.result)
      //       data.options = res.result
      //       data.currentJxid = data.options[0].jxid
      //       data.currentJxkssj = new Date(data.options[0].yxqq).Format("yyyy-MM-dd")
      //       data.currentJxjssj = new Date(data.options[0].yxqz).Format("yyyy-MM-dd")
      //       data.time_select = [data.currentJxkssj, data.currentJxjssj];
      //       data.time_section = [data.currentJxkssj, data.currentJxjssj];
      //
      //     } else {
      //       ElMessage({
      //         message: '未参加集训，暂无数据',
      //         type: "warning",
      //       });
      //     }
      //   } else {
      //     ElMessage({
      //       message: res.resultDesc,
      //       type: "warning",
      //     });
      //   }
      // })
    })
    // 获取学习报告
    const getStudyReport = () => {
      const params = {
        kssj: data.time_select[0],
        jssj: data.time_select[1],
        jxid: data.currentJxid,
        xsid: store.state.userInfo.yhid,
      }
      studyReportApi(params).then(res => {
        if (res.success) {
          if (res.result) {
            data.currentData = res.result;
            data.ljxxsc = res.result.ljxxsc;
            data.xxkwzs = res.result.xxkwzs;
            setGauge(res.result)
            setBroke(res.result)
            setCategory(res.result)
          } else {
            ElMessage({
              message: '未参加集训，暂无数据',
              type: "warning",
            });

          }
        } else {
          ElMessage({
            message: res.resultDesc,
            type: "warning",
          });

        }
      })
    }
    // 日期的禁选
    const dealDisabledDate = (time) => {
      let srart = new Date(proxy.$utils.getNowTime(0, 0, -1, data.time_section[0])).getTime();
      let now = new Date(proxy.$utils.getNowTime(0, 0, 0,)).getTime()
      let end = new Date(data.time_section[1]).getTime();
      if (now < end) {
        end = now
      }
      return time < srart || time > end
    };
    // 夏令营下拉框选项改变
    const selectChange = () => {
      data.options.forEach(ele => {
        if (ele.jxid === data.currentJxid) {
          data.currentJxkssj = new Date(ele.yxqq).Format("yyyy-MM-dd")
          data.currentJxjssj = new Date(ele.yxqz).Format("yyyy-MM-dd")
          data.time_select = [data.currentJxkssj, data.currentJxjssj];
        }
      })
      getStudyReport()
    };
    // 日期区间的改变
    const timeChange = () => {
      getStudyReport()
    };
    //  ------------------------------------------------------折线图
    const setBroke = (result) => {
      // 获取echarts Dom
      brokeEcharts = echarts.init(document.getElementById('broke'))
      // setOption设置echarts配置数据
      const listStudy = [];
      result.listStudy.forEach((item, index) => {
        listStudy[index] = item.xxsl;
      })
      data.brokeOption.series[0].data = listStudy;
      data.brokeOption.dataZoom[0].end = listStudy.length > 12 ? 100 / (listStudy.length / 10) : 100
      data.brokeOption.xAxis.data = result.rqList.slice(0, data.brokeOption.series[0].data.length);
      brokeEcharts.setOption(data.brokeOption)
      window.addEventListener("resize", function () {
        brokeEcharts.resize();
      });
    };
    //  ------------------------------------------------------柱状图
    const setCategory = (result) => {
      categoryEcharts = echarts.init(document.getElementById('category'))
      const arr = [];
      result.listStudy.forEach((item, index) => {
        const obj = {
          product: result.rqList[index],
          sxs: item.sxs,
          exs: item.exs,
          yxs: item.yxs,
          lxs: item.lxs,
        }
        arr.push(obj)
      })
      data.categoryOption.dataset.source = arr;
      data.categoryOption.dataZoom[0].end = arr.length > 12 ? 100 / (arr.length / 10) : 100
      categoryEcharts.setOption(data.categoryOption)
      window.addEventListener("resize", function () {
        categoryEcharts.resize();
      });
    };
    //  -----------------------------------------------------仪表盘
    const setGauge = (result) => {
      gaugeEcharts = echarts.init(document.getElementById('gauge'))
      data.gaugeOption.series[0].data[0].value = result.ztqk / 100
      // data.gaugeOption.series[0].data[0].value = 25
      gaugeEcharts.setOption(data.gaugeOption)
      window.addEventListener("resize", function () {
        gaugeEcharts.resize();
      });
    };
    return {
      ...toRefs(data),
      getTrains,
      dealDisabledDate,//日期的禁止
      selectChange,//集训的改变
      timeChange,//时间改变
      setGauge,//整体情况优良中差
      setBroke,//进步曲线折线图
      setCategory,//柱状图
      // gaugeEcharts,
    }
  }
}
</script>
<style lang="scss" scoped>
.secharts {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.header {
  width: 100%;
  height: 5.2vw;
  box-shadow: 0 2px 4px 2px rgba(89, 144, 255, 0.2);
}
main {
  width: 100%;
  height: calc(100% - 8.2vw);
  box-sizing: border-box;
  padding: 0.8vw 2vw;
  .select {
    height: 2.5vw;
    width: 47vw;
    background: #5990ff;
    box-sizing: border-box;
    border-radius: 4px;
    box-shadow: 0 0 4px 4px rgba(89, 144, 255, 0.2);
    padding: 0.25vw;
    position: relative;
    .icon {
      width: 1.8vw;
      height: 1.8vw;
      position: absolute;
      top: 50%;
      left: 48%;
      transform: translate(-50%, -50%);
      z-index: 3;
      img {
        display: block;
        width: 100%;
        height: 100%;
      }
    }
  }
}
.main-title {
  padding: 0 1vw;
  width: 100%;
  height: 2.4vw;
  font-size: 1.2vw;
  margin: auto;
  border-bottom: 2px solid #eee;
  position: relative;
  span {
    display: block;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }
}
.images {
  width: 100%;
  height: 88%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  // background-color: red;
  // padding: 0 3%;
  box-sizing: border-box;
  margin: auto;
  flex-wrap: nowrap;
  .img {
    width: 29%;
    height: 60%;
    background-size: 100% 100%;
    padding: 0.8vw;
    .img-title {
      width: 100%;
      height: 50%;
      font-size: 1.2vw;
      color: #fff;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
    }
    .img-main {
      width: 100%;
      text-align: center;
      font-size: 1.6vw;
      color: #fff;
      padding-top: 5px;
    }
    .img3-main {
      display: flex;
      align-items: center;
      width: 100%;
      height: 50%;
    }
    p:nth-child(2) {
      height: 40%;
      font-size: 1.1vw;
      span {
        top: 0;
        transform: translateY(0);
      }
    }
  }
  .img1 {
    background: linear-gradient(266deg, #577ff8, #9db7fc);
    border-radius: 10px;
  }
  .img2 {
    background: linear-gradient(266deg, #fc7639, #fdb289);
    border-radius: 10px;
  }
  .img3 {
    background: linear-gradient(266deg, #9a74f1, #d5bcff);
    border-radius: 10px;
    p {
      float: left;
      width: 50% !important;
    }
  }
}
.main {
  width: 100%;
  height: calc(100% - 2vw);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .main0 {
    display: flex;
    height: 40%;
    .box0 {
    }
  }
  .main1 {
    display: flex;
    height: 58%;
    margin-top: 2%;
    .main-title2 {
    }
    .box2 {
      width: 100%;
      border-radius: 10px;
      margin-top: 1%;
      padding: 0 1vw;
      box-shadow: 0 1px 5px 2px rgba(89, 144, 255, 0.2);
    }
  }
  .left1 {
    // display: flex;
    // align-items: center;
    // justify-content: space-around;
  }
  .box {
    width: 49%;
    height: 100%;
    float: left;
    border-radius: 10px;
    margin-right: 2%;
    margin-top: 1%;
    padding: 0 1vw;
    box-shadow: 0 1px 5px 2px rgba(89, 144, 255, 0.2);
    .canvas {
      width: 100%;
      height: 50%;
      box-sizing: border-box;
      padding: 2% 0;
      .canvas1 {
        width: 50%;
        height: 100%;
        float: left;
      }
      .canvas2 {
        width: 50%;
        height: 100%;
        float: left;
      }
    }
  }
  .box:nth-child(even) {
    margin-right: 0;
  }
}
.broke {
  width: 100%;
  height: calc(100% - 2vw);
}
.category {
  width: 100%;
  height: 100%;
  margin-top: -2.4vw;
}
@media (min-height: 700px) {
  .category {
    margin-top: -1.8vw;
  }
}
.echartsBox {
  width: 100%;
  height: 100%;
  .tips {
    width: 50%;
    height: 75%;
    box-sizing: border-box;
    padding: 0 3% 0 0;
    float: left;
    overflow-y: auto;
    .top {
      .blod {
        font-size: 1vw;
        color: #333;
        font-weight: 600;
      }
      .text {
        font-size: 0.9vw;
        color: #666;
        margin-top: 0.3vw;
      }
    }
    .bottom {
      margin-top: 0.5vw;
      .text {
        margin-top: 0.3vw;
      }
    }
  }
  .radar {
    width: 50%;
    height: 80%;
    float: left;
  }
}
.gauge {
  width: 100%;
  height: 100%;
  // float: right;
  // box-sizing: border-box;
  // margin-top: 13%;
}
.selsectbox {
  width: 130px;
  background: #fff;
  border-radius: 4px;
}
//自定义el-input框的一些自定义样式
/deep/ .selsectbox .el-input--small .el-input__inner {
  // background-color: #e6f0ff;
  padding-left: 40px;
  height: 50px;
  border-radius: 5px;
  // color: #1890FF;
  font-size: 18px;
  border: 1px solid #1890ff;
  font-weight: 600;
}
// //自定义左边图标
// .selsectbox /deep/.el-input__inner{
//   background: url('../../../assets/images/student/left.png') no-repeat center center; //引入icon
//   background-size: 18px 18px;  //这个是图片的大小，在这里不能直接设置width  height,设置宽高其实是select的宽高，图片可能会失真只设置宽度  高度auto也行
//   background-position:0.5vw 50%; //在input中定位置  可以调整自定义icon的左右上下位置
//   padding: 0 0 0 2.5vw; //需要设置padding 把placeholder向右移
//   box-sizing: border-box;
//   font-size: 14px;
// }
/deep/.el-select .el-input .el-select__caret {
  height: 2vw;
}
//替换右边边默认图标
/deep/.selsectbox .el-icon-arrow-up::before {
  content: "";
  background: url("../../../assets/images/student/triangle.png") center center
    no-repeat;
  position: absolute;
  background-size: 18px 12px;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(180deg);
}
/deep/ .selsectbox .el-input--small input::-webkit-input-placeholder {
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  // font-weight: 500;
  // color: #2881FF;
}
</style>
<style lang="scss">
.secharts {
  .el-input__inner {
    height: 2vw;
    line-height: 0;
  }
  .el-input__icon {
    line-height: inherit;
    width: 2vw;
  }
  .el-input__suffix-inner {
    display: block;
    height: 100%;
    // padding-top: .3vw;
  }
  .radar {
    canvas {
      // z-index: 2002 !important;
    }
  }
  .el-range-separator {
    line-height: 1.5vw;
  }
  .el-date-editor .el-range__icon {
    display: none;
  }
  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-thumb {
    background: #87c2ef;
    border-radius: 20px;
  }
}
</style>